#root {
  display: flex;
}

.app {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
  display: flex;
}

small {
  text-transform: none;
}

.no-btn {
  background: transparent;
  border: none;
  cursor: pointer;
}

.box-header {
  padding-right: 50px;
}

table.table-small-text tbody tr td {
  font-size: 13px;
}

table.table tbody tr.group-next-row td {
  border-bottom: none;
  padding-bottom: 0;
}

.modal {
  display: block;
  overflow: auto;
}

.modal.fade .modal-dialog {
  transition-duration: .15s;
}

.box-header {
  padding-right: 20px;
}

input.is-loading {
  background-image: url("./images/rotation.gif");
  background-size: 15px;
  background-position: right center;
  background-repeat: no-repeat;
  background-origin: content-box;
}
